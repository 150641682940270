// export const fetchCityGroupEndPoint = 'retailers/citygroup/v1/';

// login
export const fetchOTPEndPoint = 'users/otp/v1/';
export const fetchTokenEndPoint = 'users/tokens/v1/';

// logout
export const logoutUserEndPoint = 'dispatch/ds/logout/user/v1/'

// HEADER AND INFO PANEL APIS
export const fetchCityGroupEndPoint = 'dispatch/ds/city/v1/';
export const pickerHistoryEndpoint = 'dispatch/ds/picker/history/v1/';

// TABLESELECTION
export const fetchTableDataEndPoint = 'dispatch/ds/line/list/v2/'
export const tableEnableDisableEndPoint = 'dispatch/ds/picker/update/status/v2/'

// POPUP APIS
export const addNewTableEndPoint = 'dispatch/ds/picker/v1/'
export const addNewLineEndPoint = 'dispatch/ds/line/v1/';
export const fetchCategorySupervisorEndPoint = 'dispatch/ds/picker/line/category/v1/';
export const setRouteWiseSupervisorEndPoint = 'dispatch/ds/orders/picker/line/update/v1/'
export const fetchLineSupervisorEndPoint = 'dispatch/ds/get/supervisor_list/v1/'
export const fetchSupervisorNameEndPoint = 'dispatch/ds/line/v1/';
export const fetchLineTypeEndPoint = 'dispatch/ds/picker/move/line/v1/'
export const sendDriverLoginOtpEndPoint= 'dispatch/ds/driver/login/v1/'
export const validateDriverOtpEndPoint = 'dispatch/ds/driver/v1/';
export const fetchTransporterListEndPoint = 'dispatch/ds/transporter/v1/'
export const registerNewDriverENdPoint = 'dispatch/ds/driver/v1/';
export const sendDriverVerifyOtpEndPoint= 'dispatch/ds/driver/verify/v1/'
export const getUnscannedOrderDetailEndPoint= 'dispatch/ds/route/order/picked/dispatch/v1/'

// UNMAPPED RETAILERS / EDIT ROUTE 
export const fetchUnmappedRetailerEndpoint = 'dispatch/ds/route/unmapped/retailers/v2/'
export const updateRouteDetailsEndPoint = 'dispatch/ds/update/route/v1/' 
export const addNewRouteEndPoint = 'dispatch/ds/route/v1/'
export const fetchOnlyRouteListEndPoint = 'dispatch/ds/route/list/retailer/mapping/v1/'
export const unassignRouteDriverEndPoint = 'dispatch/ds/driver/unassign/v1/'

// TABLE DASHBOARD DATA - SUPERVISOR WISE TABLE DETAILS API
export const fetchTableDetailEndPoint = 'dispatch/ds/dcscanner/v1/';
export const editSupervisorInfoEndpoint = 'dispatch/ds/line/v1/';

// ROUTE LISTING APIS
export const fetchRouteListEndPoint = 'dispatch/ds/route/citywise/v1/'
export const fetchRouteWiseResellerEndPoint = 'dispatch/ds/route/retailers/v1/';
export const autoAssignDriverEndPoint = 'dispatch/ds/route/driver_auto_assign/v1/';
export const dispatchRoutesEndPoint = 'dispatch/ds/route/dispatch/v1/'
export const freezeRoutesEndPoint = 'dispatch/ds/route/freeze/v1/'
export const autoAssignSupervisorEndPoint = 'dispatch/ds/auto/assign/cold/line/v1/'
export const autoAssignPickerEndPoint = 'dispatch/ds/cold/auto/picker/assign/v1/'
export const updateSupervisorOnlyEndPoint = 'dispatch/ds/route/line/v1/'
export const enableRetailerCutoffEndpoint = 'dispatch/ds/early_picking/v1/'

// PRINT SHEET AND REPORT  
export const printResellerReportEndpoint ='dispatch/ds/route/reseller_report/v1/'
export const printTripSheetEndpoint= 'dispatch/ds/route/trip_sheet/v1/'
export const printPickeReciptEndPoint = 'dispatch/ds/dcscanner/table_receipt/v1/'
export const preCutoffEndPoint = 'dispatch/ds/dcscanner/update_picking_status/v1/'
export const bulkColdPickerListEndPoint = 'dispatch/ds/citywise/cold/picklist/v1/'
export const bulkFmcgPickerListEndPoint = 'dispatch/ds/agents/orders/bulk/fmcg/picklist/v1/'
export const printHandoverSheetEndpoint = 'dispatch/ds/route/hand_over/sheet/v1/'

export const printRouteAiPickerslipEndpoint = 'dispatch/ds/agents/orders/picklist/v1/'
export const printAiPickerslipEndpoint = 'dispatch/ds/agent/orders/picklist/v1/'

export const printTowerPickerslipEndpoint = 'dispatch/ds/agent/order/tower/picklist/v1/'
export const printPocTripSheetEndpoint= 'dispatch/ds/route/agent/tower/trip_sheet/v1/'

export const printFnvPickslipEndpoint = 'dispatch/ds/agents/orders/prod-type/picklist/v1/'
export const printNewAiPickslipEndpoint = 'dispatch/ds/agent/ai/picklist/v1/'
export const printLongTailAiPickslipEndpoint = 'dispatch/ds/agent/ai/long/tail/picklist/v1/'
export const printEarlyPickslipEndpoint = 'dispatch/ds/agent/ai/early/scan/picklist/v1/'

// ADD NEW ROUTE
export const fetchRouteSeqEndPoint = 'dispatch/ds/route/sequence/v1/';

// RETAILER ORDERS 
export const fetchRetailerWiseOrdersEndPoint = 'dispatch/ds/retailer/orders/v1/'
export const fetchOrderDataEndPoint = 'dispatch/ds/consumer/orders/details/v1/';
export const fetchCategoryWiseRetalerTableEndPoint ='dispatch/ds/picker/list/v1/';
export const updateRetailerCategoryPickerEndPoint = 'dispatch/ds/agent/picker/update/v1/';


// PRINT CITYWISE PICKLIST - COLD
export const moveOrderToProcessing = 'dispatch/ds/dcscanner/update_agent_order_picking_status/v1/';
export const fetchCitywisePicklist = 'dispatch/ds/agent/cold/picklist/v1/';
export const fetchChamberPicklist = `dispatch/ds/agent/cold/chamber/picklist/v1/`

// FETCH AGENT WISE CONSUMER LIST - COLD
export const fetchAgentOrderList = 'dispatch/ds/agent/cold/order/list/v1/' 
export const fetchRetailerOrderDetailsEndPoint = "scanning/light_scanning/order_scan/v1/"

// REUSABLE BAG LEDGER
export const fetchReusableBagLedgerEndPoint = 'dispatch/ds/reusable_bag/ledger/v1/'

// TABLE STATUS
export const fetchTimeDurationListEndPoint = 'dispatch/ds/scanning/slots/v1/';
export const fetchTableStatusDetailEndPoint = 'dispatch/ds/scanning/dashboard/v1/';
export const fetchShortfallDetailsEndPoint = 'dispatch/ds/scanning/table_shortfall/v1/';

// ONDC ORDER LISTING
export const fetchOndcOrderListEndpoint = "dispatch/ds/ondc/orders/v1/";
export const fetchOndcOrderDetailsEndpoint = "dispatch/ds/ondc/order/details/v1/";
export const fetchAllOndcOrderDetailsEndpoint = "dispatch/ds/ondc/order/items/details/excel/v1/";


// PICKER SHORTFALL DETAIL
export const fetchShortfallDataEndpoint = 'dispatch/ds/line/picker/shortfall/v1/'