import { createSlice } from "@reduxjs/toolkit";

export const HomeSlice = createSlice({
  name: "homeReducer",
  initialState: {
    selDist: {},
    distList: [],
    distObj: {},
  },
  reducers: {
    reset: (state, action) => {
      // return HomeSlice.getInitialState();
    },
    setSelDist: (state, action) => {
      state.selDist = action.payload;
    },
    setDist: (state, action) => {
      let dcList = [];
      for(let dc in action.payload){
        dcList.push(action.payload[dc]);
      }
      state.distList = dcList;
      state.distObj = action.payload
    },

  },
});

export const { reset, setSelDist, setDist } =
  HomeSlice.actions;

export default HomeSlice.reducer;
