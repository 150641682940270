import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import ProtectedRoute from "./utils/ProtectedRoute";
import NavBar from "./commonComponent/Drawer/NavBar";
import Loader from "./commonComponent/Loader/Loader";

const Home = lazy(() => import("./screens/Home"));
const Login = lazy(() => import("./screens/Login"));
const FnvScanning = lazy(() => import("./screens/FnvScanning"));
const MultiOdrFnvScanning = lazy(() => import("./screens/MultiOdrFnvScanning"));
const SupervisorRouteListing = lazy(() => import("./screens/SupervisorRouteListing"));
const PocSupervisorRouteListing = lazy(() => import("./screens/PocSupervisorRouteListing"));
const RouteListing = lazy(() => import("./screens/RouteListing"));
const Supervisor = lazy(() => import("./screens/Supervisor"));
const TableDashboard = lazy(() => import("./screens/TableDashboard"));
const PocColdScanning = lazy(()=> import("./screens/ColdScanningPoc"))
const AgentOrderListing = lazy(()=> import("./screens/ColdAgentConsumerListing"))
const ReturnReusableBag = lazy(()=> import("./screens/ReturnReusableBag"))
const TableStatus = lazy(()=> import("./screens/TableStatus"))
const BagInventory = lazy(()=> import("./screens/BagInventory"))
const PartnerBagInventory = lazy(()=> import("./screens/BagInventory/components/PartnerBagInventory"))
const OndcOrderListing = lazy(()=> import("./screens/OndcOrderListing"))
const DebitReusableBag = lazy(()=> import("./screens/DebitReusableBag"))
const ShortfallDashboard = lazy(()=> import("./screens/ShortfallDashboard"))

export default function Routing() {
  return (
    <BrowserRouter>
      <Routes>

        <Route
          path="/login"
          element={
            <Suspense fallback={<Loader />}>
              <Login />
            </Suspense>
          }
        ></Route>
        
        <Route element={<ProtectedRoute />}>
          <Route element={<WithNavbar />}>
            <Route
              path="/"
              element={
                <Suspense fallback={<Loader />}>
                  <Home />
                </Suspense>
              }
            ></Route>
            <Route
              path="/fnv-scanning/"
              element={
                <Suspense fallback={<Loader />}>
                  <FnvScanning />
                </Suspense>
              }
            ></Route>
            <Route
              path="/multi-fnv-scanning/"
              element={
                <Suspense fallback={<Loader />}>
                  <MultiOdrFnvScanning />
                </Suspense>
              }
            ></Route>
            <Route
              path="/routedetail/*"
              element={
                <Suspense fallback={<Loader />}>
                  <SupervisorRouteListing />
                </Suspense>
              }
            ></Route>
            <Route
              path="/new/routedetail/*"
              element={
                <Suspense fallback={<Loader />}>
                  <PocSupervisorRouteListing />
                </Suspense>
              }
            ></Route>
            <Route
              path="/route/*"
              element={
                <Suspense fallback={<Loader />}>
                  <RouteListing />
                </Suspense>
              }
            ></Route>
            <Route
              path="/supervisor/*"
              element={
                <Suspense fallback={<Loader />}>
                  <Supervisor />
                </Suspense>
              }
            ></Route>
            <Route
              path="/table-dashboard/*"
              element={
                <Suspense fallback={<Loader />}>
                  <TableDashboard />
                </Suspense>
              }
            ></Route>
            <Route
              path="/table-status/:slotId/"
              element={
                <Suspense fallback={<Loader />}>
                  <TableStatus />
                </Suspense>
              }
            ></Route>
            <Route
              path="/table-dashboard/*"
              element={
                <Suspense fallback={<Loader />}>
                  <TableDashboard />
                </Suspense>
              }
            ></Route>
            <Route
              path="/cold-scanning/"
              element={
                <Suspense fallback={<Loader />}>
                  <PocColdScanning/>
                </Suspense>
              }
            ></Route>   
            <Route
              path="/retailers-order-list/:agentId/:pickerId/"
              element={
                <Suspense fallback={<Loader />}>
                  <AgentOrderListing />
                </Suspense>
              }
            ></Route>     
            <Route
              path="/reusable-return-bag/"
              element={
                <Suspense fallback={<Loader />}>
                  <ReturnReusableBag />
                </Suspense>
              }
            ></Route>  
            <Route
              path="/dc-bag-inventory/"
              element={
                <Suspense fallback={<Loader />}>
                  <BagInventory />
                </Suspense>
              }
            ></Route>  
             <Route
              path="/partner-bag-inventory//"
              element={
                <Suspense fallback={<Loader />}>
                  <PartnerBagInventory />
                </Suspense>
              }
            ></Route>  
            <Route
              path="/ondc-orders/:slotId/"
              element={
                <Suspense fallback={<Loader />}>
                  <OndcOrderListing />
                </Suspense>
              }
            ></Route> 

            <Route
              path="/debit-reusable-bag/"
              element={
                <Suspense fallback={<Loader />}>
                  <DebitReusableBag />
                </Suspense>
              }
            ></Route> 

            <Route
              path="/shortfall-dashboard/:slotId/"
              element={
                <Suspense fallback={<Loader />}>
                  <ShortfallDashboard />
                </Suspense>
              }
            ></Route> 


          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function WithNavbar() {
  return (
    <>
      <NavBar />
      <Outlet />
    </>
  );
}
