import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logoutUser } from "../../utils/api";
import MenuIcon from "../../icons/menu-white.png";
import ArrowLeft from "../../icons/arrow-left-2.png";
import CloseIcon from "../../icons/close.png";
import { SidebarData } from "./SidebarData";
import "./NavBar.css";
import { ReactComponent as LogoutIcon } from "../../icons/logout.svg";
import Loader from "../Loader/Loader";
import { ErrorHandling } from "../../State";

function NavBar(props) {
  const [sidebar, setSidebar] = useState(false);
  const [loading, setLoader] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const navigation = useNavigate();

  const handleNavigation = (item) => {
    let route = item.path;

    if(item.hasDate){
      let date = localStorage.getItem("dc_date");

      if(isNaN(date)){
        let today = new Date();
        date = 
          today.getFullYear() + 
          (parseInt(today.getMonth() + 1) > 9 ? `0${parseInt(today.getMonth()+1)}` : parseInt(today.getMonth())) + 
          (today.getDate() > 9 ? today.getDate() : '0' + today.getDate())
      }
      route = item.path + date + item.pathEnd;
    }

    navigation(route)
    showSidebar()
  } 

  const logout = async() => {
    setLoader(true)
    let res = await logoutUser({})
    setLoader(false)
    if(res){
      console.log("logout");
      localStorage.clear();
      navigation("/login");
    }
  };
  
  return (
    <>
      <div className="navbar">
        <Link to="#">
          <img
            src={MenuIcon}
            onClick={showSidebar}
            className="iconDrawer"
          />
        </Link>
        {/* <Link to="/">
          <img
            src={ArrowLeft}
            className="iconBack"
          />
        </Link> */}
      </div>
      <nav className={sidebar ? "navMenu active" : "navMenu"}>
        <ul>
          <img
            className={sidebar ? 'closeIcon active' : 'closeIcon'}
            src={CloseIcon}
            onClick={showSidebar}
            style={{ width: "2.1rem", marginBottom: "0.8rem" }}
          />
          <div className="userDetail">
            <div className="fontL capital">{localStorage.getItem("name")}</div>
            <div className="fontM">{localStorage.getItem("ename")}</div>
          </div>
          {/* user details  */}
          {SidebarData.map((item, index) => {
            return (
              <div onClick={() => handleNavigation(item)} key={index} className={`pointer ${(item.is_experimental ? ((localStorage.getItem('distributor_id') == 6) ? '' : 'hide') : '')}`}>
                <li key={index} className={item.cName}>
                  <img
                    src={item.image}
                    style={{
                      width: "1.8rem",
                      marginRight: "0.6rem",
                      marginBottom: "-0.3rem",
                    }}
                  />
                  <span className={`${item.is_experimental ? 'brightYellow' : ''}`}> {item.title} </span>
                </li>
              </div>
            );
          })}
        </ul>
        <button className="logoutBtn" onClick={logout}>
          <LogoutIcon className="logoutIcon" /> logout
        </button>
      </nav>
      <div
        onClick={showSidebar}
        className={sidebar ? "dummyDiv active" : "dummyDiv"}
      ></div>
      {loading && <Loader />}
    </>
  );
}

export default NavBar;
