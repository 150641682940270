import { Base } from "./config";
import { get_data, post_data, put_data } from "./NetworkHandler";
import { ErrorHandling, SuccessHandling } from "../State";
import { 
  enableRetailerCutoffEndpoint, 
  fetchAgentOrderList, 
  fetchOTPEndPoint, 
  fetchRetailerOrderDetailsEndPoint, 
  fetchReusableBagLedgerEndPoint, 
  fetchTokenEndPoint, 
  logoutUserEndPoint, 
  sendDriverVerifyOtpEndPoint, 
  unassignRouteDriverEndPoint, 
  updateRetailerCategoryPickerEndPoint, 
  updateSupervisorOnlyEndPoint, 
  fetchTimeDurationListEndPoint, 
  fetchTableStatusDetailEndPoint, 
  fetchShortfallDetailsEndPoint, 
  getUnscannedOrderDetailEndPoint, 
  fetchOndcOrderListEndpoint,
  addNewLineEndPoint,
  addNewTableEndPoint,
  autoAssignDriverEndPoint,
  dispatchRoutesEndPoint,
  editSupervisorInfoEndpoint,
  fetchCityGroupEndPoint,
  fetchLineSupervisorEndPoint,
  fetchCategorySupervisorEndPoint,
  fetchLineTypeEndPoint,
  fetchOrderDataEndPoint,
  fetchRetailerWiseOrdersEndPoint,
  fetchRouteListEndPoint,
  fetchRouteSeqEndPoint,
  fetchRouteWiseResellerEndPoint,
  fetchSupervisorNameEndPoint,
  fetchTableDataEndPoint,
  fetchTableDetailEndPoint,
  fetchTransporterListEndPoint,
  fetchUnmappedRetailerEndpoint,
  pickerHistoryEndpoint,
  registerNewDriverENdPoint,
  sendDriverLoginOtpEndPoint,
  tableEnableDisableEndPoint,
  validateDriverOtpEndPoint,
  setRouteWiseSupervisorEndPoint,
  updateRouteDetailsEndPoint,
  fetchCategoryWiseRetalerTableEndPoint,
  freezeRoutesEndPoint,
  addNewRouteEndPoint,
  fetchOnlyRouteListEndPoint,
  autoAssignSupervisorEndPoint,
  autoAssignPickerEndPoint,
  fetchOndcOrderDetailsEndpoint,
  fetchAllOndcOrderDetailsEndpoint,
  fetchShortfallDataEndpoint,
} from "./apiEndPoints";
import store from "../app/store";
import { setInfoData } from "../app/InfoPanelReducer";
import {
  setCategorySupervisorList,
  setOrderDetail,
  setRetailerInfo,
  setRetailerOrders,
  setRetailerPickerInfo,
  setRouteList,
  setTableData,
  setTableDetail,
  setUnmappedList,
  toggleDisable,
} from "../app/TableOverviewReducer";
import { setUserInfo } from "./auth";
import { allowedDc, allowedSwitcherPhone } from "./constData";
import { MonthNames } from "./constData";


// switcher conditions: 
export function showSwitcher(){
  let userPhone = localStorage.getItem('phone')
  let userDistriutorId = parseInt(localStorage.getItem('distributor_id'));

  let hasUser = allowedSwitcherPhone.includes(userPhone);
  let hasDc = allowedDc.includes(userDistriutorId)

  return (hasDc || hasUser);
}

// DROPDOWN TOGGLE
export function toggle(index, class_name='hide') {
  let child = document.getElementById(`child_${index}`);
  child.classList.toggle(class_name);
}

// formatdate
export function unformatDate(date, hasYYYY) {
  if(date.length === 6 )
    return date
  date = date.split("-");
  if(hasYYYY){
    date = date[2] + date[1] + date[0].substring(2);
  }
  else{
    date = date[2] + date[1] + date[0];
  }
  return date;
}

// formate date according to the input
export function formateDate(date){
  if(date.length === 6){
    date = `${date.substring(4)}-${date.substring(2,4)}-${date.substring(0,2)}`;
  }
  return date
}

// deep copy of obj / array
export function deepCopyObject(obj){
  let deepCopiedObj = JSON.parse(JSON.stringify( obj))
  return deepCopiedObj
}

// check use permission permission
export function handlePermissionCheck(permission){
  let userPermissions = localStorage.getItem('permissions');
  userPermissions = userPermissions.split(',')
  let havePermission = userPermissions.includes(permission) 
  return havePermission
}

// scroll pixel
export function scroll(id, pixel){
  setTimeout(function () {
      // Get the element you want to scroll
      var element = document.getElementById(id);
  
      // Set the scrollLeft property to 2000
      element.scrollLeft = pixel;
  }, 10);
}

/* ------------- XLSX FILE FUNCTIONS START HERE------------- */

// export excel using xlsx
export async function downloadExcelFile(data, fileName, fileExtension){
  import(('xlsx'))
  .then(({utils, writeFile}) => {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, `${fileName}.${fileExtension}`);
  })
  .catch((error) => {
    console.log(error)
  })
}

/* ------------- XLSX FILE FUNCTIONS ENDS HERE------------- */



// TIME MANAGER - epoch should be in miliseconds
export function EpochTimeInHumanLan(timing, hrs_12_format) {
  // let date_time = new Date(parseInt(epoch_time + '000'))
  
  let epoch_time = new Date(timing)
  epoch_time = Date.parse(timing)
  
  // epoch time check
  if(!epoch_time){
    return '--'
  }
  
  let date_time = new Date(parseInt(epoch_time))
  let month = MonthNames[date_time.getMonth()]
  let year = String(date_time.getFullYear())
  let dat_value = date_time.getDate() >= 10 ? String(date_time.getDate()) : '0' + String(date_time.getDate())
  let setMinutes = date_time.getMinutes() >= 10 ? String(date_time.getMinutes()) : String('0' + date_time.getMinutes())
  let hours = parseInt(date_time.getHours()) >= 10 ? String(date_time.getHours()) : String('0' + date_time.getHours())

  let am_or_pm = "" 
  if(hrs_12_format){
      hours = hours > 12 ? hours - 12 : hours;
      am_or_pm = date_time.getHours() >= 12 ? 'PM' : 'AM'
  }
  
  let dateObj =  month.substring(0, 3) + ' ' + dat_value + ', ' + year + " "+ hours + ':' + setMinutes + ':00 ' + am_or_pm
  return dateObj
}

// TIME MANAGER - get date in DDMMMYYYY - 22Sep2024 from slot_id
export function slotToHumanReadableDate(slotId){
  let date = slotId.substring(0,2);

  let month = slotId.substring(2,4);
  month = MonthNames[month - 1];
  month = month.substring(0,3);

  let year = '20' + slotId.substring(4);

  return `${date}${month}${year}`;
}

// LOGIN - send otp at login - post api
export async function sendOtpUtil(phone){
  const url = Base + fetchOTPEndPoint;

  const data = { phone };

  return await post_data(url, data)
  .then(response => {
    if (response.success)
      return true;
    else
      ErrorHandling(response.error.message);
  }).
  catch(error => ErrorHandling(error.message))
}

// LOGIN - sign in with otp - post api
export async function signInUtil(phone, otp){
  const url = Base + fetchTokenEndPoint;

  const data = { phone, otp }

  return await post_data(url, data).then((response) => {
    console.log(response);
    if (response && response.user_id) {
      setUserInfo(response);
      return true;
    } else {
      ErrorHandling(response.error.message);
    }
  }).catch((error) => ErrorHandling(error.message));
}

// LOGOUT - logout - put api
// POPUP - TABLE DASHBOARD - Allow Table To login in new - put api
export async function logoutUser(data){
  let url = Base + logoutUserEndPoint
  return put_data(url, data)
  .then((res) => {
    if(res.success){
      SuccessHandling(res.message)
      return true;
    }
    else return Promise.reject(res.error)
  })
  .catch((error) => {
    ErrorHandling(error.message)
    return false
  })
}

// VALIDATE NEW ROUTE / UPDATE ROUTE DATA 
export function validateData(list){
  let data = [];
  let del_data = [];
  let del_num_list = []
  let all_correct = true;
  for(let ret of list){
    // console.log(ret)
    if(!ret.is_deleted){

        let retailer = {}
        retailer.retailer_id = ret.retailer_id;
        retailer.delivery_number = ret.order_info.delivery_number;
        if(data.length === 0){
          del_num_list.push(parseInt(ret.order_info.delivery_number))
          data.push(retailer);
        }
        else{
          // debugger;
          if(del_num_list.indexOf(ret.order_info.delivery_number) > -1 ){
            all_correct = false;
            ErrorHandling(`Redundant delivery number for ${ret.retailer_info.name} !`);
            return {
              valid: all_correct,
              retailers: data,
              delete_retailer_ids: del_data,
            }
          }
          else if(ret.order_info.delivery_number == 0 || ret.order_info.delivery_number == undefined){
            ErrorHandling(`select delivery number for ${ret.retailer_info.name} !`);
          }
          else{
            del_num_list.push(parseInt(ret.order_info.delivery_number))
            data.push(retailer);
          }

        }
    }
    else{
      del_data.push(ret.retailer_id)
    }

  }

  return {
    valid: all_correct,
    retailers: data,
    delete_retailer_ids: del_data,
  }
}

// POPUP - NEW TABLE -  supervisor list for table - get api
export async function fetchSupervisorName() {
  // let url = "http://stageapi.crofarm.com/v2/dispatch/ds/line/v1/";
  let url = Base + fetchSupervisorNameEndPoint;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      console.log("supervisor Name list");
      return res.data;
    }
  });
  return res;
}

// POPUP - NEW TABLE  - to create new table - post api
export async function addNewTable(phone, table, id, picker_type) {
  let url = Base + addNewTableEndPoint;
  let data = {
    phone: parseInt(phone),
    name: table,
    line_id: id,
    picker_category: picker_type,
  };
  let res = await post_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling(res.message);
        return res.success;
      }
      else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return false
    });
  return res;
}

// POPUP - NEW LINE - get list of supervisor for new line - get api
export async function fetchLineSupervisorList() {
  let url = Base + fetchLineSupervisorEndPoint;
  return await get_data(url)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return [];
    });
}

// POPUP - CATEGORY WISE SUPERVISOR AND TABLE LISTING - get api
export async function fetchCategorySupervisor() {
  let url = Base + fetchCategorySupervisorEndPoint;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        store.dispatch(setCategorySupervisorList(res.data));
      }

      return res.data;
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return [];
    });
}

// POPUP - CATEGORY WISE SUPERVISOR - put api
export async function setRouteWiseSupervisor(data, slotId) {
  let url = Base + setRouteWiseSupervisorEndPoint + `${slotId}/`;
  let putData = {
    data: data,
  };
  console.log("SUBMIT DATA", putData);
  return await put_data(url, putData)
    .then((res) => {
      if(res.success){
        SuccessHandling(res.message);
        return res.success;
      }
      else 
        return Promise.reject(res.error)
    })
    .catch((error) => {
      ErrorHandling(error.message);
    });
}

// POPUP - NEW LINE / NEW TABLE  - get list of supervisor for new line - get api
export async function fetchLineType() {
  let url = Base + fetchLineTypeEndPoint;
  return await get_data(url)
    .then((res) => {
      // console.log("__LINE TYPE DATA__", res);
      return res.data;
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return [];
    });
}

// POPUP - NEW LINE - create a new line - post api
export async function addNewLine(data) {
  let url = Base + addNewLineEndPoint;
  await post_data(url, data)
    .then((res) => {
      SuccessHandling("Added successfully");
    })
    .catch((error) => {
      ErrorHandling(error.message);
    });
}

// POPUP - DRIVER LOGIN - send otp - put api
export async function sendLoginOtp(data) {
  let url = Base + sendDriverLoginOtpEndPoint;
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling(res.message);
        return true;
      } else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return false;
    });
}

// POPUP - DRIVER VERIFY - send otp - post api
export async function sendVerifyOtp(data) {
  let url = Base + sendDriverVerifyOtpEndPoint;
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling(res.message);
        return true;
      } else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return false;
    });
}

// POPUP - DRIVER VERIFY OTP VALIDATE - validate otp - put api
export async function validateDriverVerifyOtp(data) {
  let url = Base + sendDriverVerifyOtpEndPoint;
  // debugger;
  return await put_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling(res.message);
        return true;
      } else return Promise.reject(res.error);
    })
    .catch((error) => {
      console.log("ERROR", error);
      ErrorHandling(error.message);
      return false;
    });
}

// POUP - REGISTER DRIVER - create new driver - post api
export async function resigterNewDriver(data) {
  let url = Base + registerNewDriverENdPoint;
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        return true;
      } else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return false;
    });
}

// POPUP - DRIVER OTP VALIDATE - validate otp - put api
export async function validateDriverOtp(data) {
  let url = Base + validateDriverOtpEndPoint;
  // debugger;
  return await put_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling(res.message);
        return true;
      } else return Promise.reject(res.error);
    })
    .catch((error) => {
      console.log("ERROR", error);
      ErrorHandling(error.message);
      return false;
    });
}

// POPUP - DRIVER TRANSPORTER LIST - get api
export async function fetchTransporterList() {
  let url =
    Base +
    fetchTransporterListEndPoint;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        console.log("transporter list", res);
        return res.data;
      } else return [];
    })
    .catch((error) => {
      console.log("trasnporter list error: ", error);
      return [];
    });
}

// HEADER - city list - get api
export async function fetchCityGroup() {
  // let url = Product + fetchCityGroupEndPoint;
  let url = Base + fetchCityGroupEndPoint;
  let res = await get_data(url)
    .then((response) => {
      if (response.success) {
        // console.log("res of city group", response.data);
        return response;
      } else return Promise.reject(response.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
    });
  return res.data;
}

// INFO PANEL - info data - get api
export async function fetchInfoPanelData(cityId, slotId = 0) {
  let date = localStorage.getItem("dc_date");
  if (slotId) {
    date = unformatDate(slotId);
  }
  let url =
    Base + pickerHistoryEndpoint + `?slot_id=${date}&city_group_id=${cityId}`;
  //'http://stageapi.crofarm.com/v2/dispatch/ds/picker/history/v1/?slot_id=100422'
  await get_data(url)
    .then((res) => {
      // console.log("INFO PANEL DATA---------", res);
      if (res.success) {
        store.dispatch(setInfoData(res.data));
        if (
          window.location.pathname === "/route" &&
          res.data.is_picker_confirmed
        ) {
          window.location.href = "/route/dashboard";
        }
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
    });
}

// TABLE SELECTION - get previous day's active tables - get api
export async function fetchTableData() {
  let url = Base + fetchTableDataEndPoint + localStorage.getItem('dc_date') +'/'; 
  await get_data(url)
    .then((res) => {
      // console.log("TABLE SELECTION DATA", res);
      store.dispatch(setTableData(res.data));
    })
    .catch((error) => {
      ErrorHandling(error.message);
    });
}

// TABLE SELECTION - number of tables in previous supervisor - table count - internal calculation;
export function preCount(index) {
  // console.log("working", index);
  let list = store.getState().tableOverview.tableData;
  let count = 0;
  if (store.getState().tableOverview.tableData.length) {
    for (let i = 0; i <= index - 1; i++) {
      count += list[i].picker_data.length;
      // console.log("list", list[i].picker_data)
      // console.log("count", count)
    }
  }
  return count;
}

// TABLE SELECTION - enable disable table - put api
export async function tableEnableDisable(data) {
  console.log("put api call to update active status of table");
  let url = Base + tableEnableDisableEndPoint;
  let picker_data = {
    picker_id: data.pickerId,
    slot_id: localStorage.getItem("dc_date"),
  };
  await put_data(url, picker_data)
    .then((res) => {
      if (res.success) {
        store.dispatch(toggleDisable(data));
        SuccessHandling("Table " + res.message);
      } else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
    });
}

// TABLE SELECTION - confirm table - post api
export async function confirmWorkingTables() {
  let url = Base + pickerHistoryEndpoint;
  let data = {
    slot_id: localStorage.getItem("dc_date"),
  };
  // console.log(url);
  await post_data(url, data)
    .then((res) => {
      SuccessHandling(res.message);
    })
    .catch((error) => {
      ErrorHandling(error.message);
    });
}

// TABLE DASHBOARD - SUPERVISOR TABLE EDIT/MOVE - table detail ( table dashboard ) get api call
export async function fetchTableDetail() {
  let url =
    Base +
    fetchTableDetailEndPoint +
    `?slot_id=${localStorage.getItem("dc_date")}`;
  //'http://stageapi.crofarm.com/v2/dispatch/ds/dcscanner/v1/?slot_id=100422';
  await get_data(url)
    .then((res) => {
      store.dispatch(setTableDetail(res.data));
    })
    .catch((error) => {
      console.log("error", error);
      ErrorHandling(error.message);
    });
}

// EDIT SUPERVISOR TABLE - supervisor table move - put api
export async function moveTable(pickerId, lineId) {
  let url = Base + fetchLineTypeEndPoint ;
  let data = {
    picker_id: pickerId,
    line_id: lineId,
    slot_id: localStorage.getItem('dc_date')
  };
  await put_data(url, data)
    .then((res) => {
      if(res.success)
        SuccessHandling(res.message);
      else 
        return Promise.reject(res.error)
    })
    .catch((error) => {
      ErrorHandling(error.message);
    });
}

// EDIT SUPERVISOR TABLE - supervisor info edit - put
export async function editSupervisorInfo(data) {
  let url = Base + editSupervisorInfoEndpoint;
  console.log("--data--", data);
  await put_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling(res.message);
      }
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
    });
}

// UNMAPPED RETAILERS - list of unmapped retailers - get api
export async function fetchUnmappedRetailer(slotId, city_group_id) {
  let url =
    Base +
    fetchUnmappedRetailerEndpoint +
    slotId +
    `/?city_group_id=${city_group_id}`;
  // `${localStorage.getItem('dc_date')}/`;
  await get_data(url)
    .then((res) => {
      console.log("---UNMAP RESELLERS---", res);
      if (res.success) {
        store.dispatch(setUnmappedList(res.data.route_retailers_info));
      } else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
    });
}

// UNMAPPED RETAILERS - list of city wise route - get api
export async function fetchOnlyRouteList(slotId, city_group_id){
  let url = `${Base}${fetchOnlyRouteListEndPoint}${slotId}/?city_group_id=${city_group_id}`;
  return await get_data(url)
  .then((res) => {
    if(res.success)
      return res.data;
    else 
      return Promise.reject(res.error);
  })
  .catch((error) => {
    ErrorHandling(error.message);
    return [];
  })
}

// UNMAPPED RETAILERS - update unmapped retailer to routes independently - put api
export async function updateRetailerToRoute(slotId, data){
  // let url = http://stageapi.crofarm.com/v2/dispatch/ds/route/list/retailer/mapping/v1/040922/;
  let url = `${Base}${fetchOnlyRouteListEndPoint}${slotId}/`;
  await put_data(url, data)
  .then((res) => {
    if(res.success)
      SuccessHandling(res.message);
    else 
      return Promise.reject(res.error);
  })
  .catch((error) => {
    ErrorHandling(error.message);
  })
}

// ROUTE LISTING / CATEGORY ROUTE LISTING - city wise route list - get api
export async function fetchRouteList(city_group_id, slot, isAll, dairy, isSwitchPicker) {
  let date = unformatDate(slot);
  let url =
    Base +
    fetchRouteListEndPoint +
    `?city_group_id=${city_group_id}&slot_id=${date}&is_switch_picker=${!!isSwitchPicker ? 1 : 0 }`;

  if( isAll) {
    url += `&is_all=1`;
  }
  else {
    url += `&is_dairy=${dairy}`;
  }
  await get_data(url)
    .then((res) => {
      if(res.success && res.data.length)
        store.dispatch(setRouteList(res.data[0]));
      else if (res.success ){
        let res = {
          city_group_id:city_group_id, 
          is_cut_off_complete: 0,
          routes: []
        }
        store.dispatch(setRouteList(res));
      }
      else if(!res.success)
        return Promise.reject(res.error);

    })
    .catch((error) => {
      store.dispatch(setRouteList({routes: []}));
      ErrorHandling(error.message);
    });
}

// ROUTE LISTING / UPDATE ROUTE - route wise resellers - get api
export async function fetchRouteWiseReseller(routeId, slotId, isAll, dairy) {
  let url =
    Base + fetchRouteWiseResellerEndPoint + slotId + "/" + routeId + "/";
  
  if(isAll){
    url += `?is_all=1`
  }
  else{
    url += `?is_dairy=${dairy}`
  }
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      } else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return [];
    });
}

// ROUTE LISTING - POPUP - picker to number of route count data - get api
export async function fetchAutoAssignmentPickerData(slotId, cityGroupId){
  let url = Base + autoAssignPickerEndPoint + `?slot_id=${slotId}&city_group_id=${cityGroupId}`;
  return await get_data(url)
  .then((res) => {
    if(res.success){
      return res.data;
    }
    else{
      return Promise.reject(res.error)
    }
  })
  .catch((error) => {
    ErrorHandling(error.message)
  })
}

// ROUTE LISTING -  auto assign supervisor - put api
export async function autoAssignSupervisor(slotId, cityGroupId, isCutoff){
  let url = Base + autoAssignSupervisorEndPoint;
  let data = {
    slot_id: slotId,
    city_group_id: cityGroupId,
    is_cutoff: isCutoff,
  }
  return await put_data(url, data)
  .then((res)=>{
    if(res.success){
      SuccessHandling(res.message)
      return res.success
    }
    else {
      return Promise.reject(res.error)
    }
  })
  .catch((error)=>{
    ErrorHandling(error.message);
    return false;
  })
}

// ROUTE LISTING -  auto assign picker - put api
export async function autoAssignPicker(slotId, cityGroupId, isCutoff){
  let url = Base + autoAssignPickerEndPoint;
  let data = {
    slot_id: slotId,
    city_group_id: cityGroupId,
    is_cutoff: isCutoff,
  }
  return await put_data(url, data)
  .then((res)=>{
    if(res.success){
      SuccessHandling(res.message)
      return res.success
    }
    else {
      return Promise.reject(res.error)
    }
  })
  .catch((error)=>{
    ErrorHandling(error.message);
    return false;
  })
}

// ROUTE LISTING - auto assign driver - put api
export async function autoAssignDriver(city, slotId) {
  let url = Base + autoAssignDriverEndPoint;
  let data = {
    slot_id: slotId,
    city_group_id: city,
  };
  await put_data(url, data)
    .then((res) => {
      if (res.success) SuccessHandling(res.message);
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      console.log(error);
      ErrorHandling(error.message);
    });
}

// ROUTE LISTING - freeze route - put api
export async function freezeRoutes(slot, cityGroupId){
  let url = Base + freezeRoutesEndPoint + slot +"/";
  let data = {
    is_agent : 1,
    city_group_id : cityGroupId
  }
  await put_data(url ,data)
  .then((res) => {
    if(res.success)
      SuccessHandling(res.message);
    else
      return Promise.reject(res.error);
  })
  .catch((error)=>{
    ErrorHandling(error.message);
  })
}

// ROUTE LISTING - dispatch route - put api
export async function dispatchRoutes(routeId, cityId, slotId, driverPhone) {
  let url = Base + dispatchRoutesEndPoint;
  let data = {
    slot_id: slotId,
    route_id: routeId,
    is_agent: 1,
    city_id: cityId,
    driver_phone: driverPhone,
  };

  await put_data(url, data)
    .then((res) => {
      console.log("---res---", res);
      if (res.success) {
        SuccessHandling(res.message);
      } else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
    });
}
  
// ROUTE LISTING - verified driver list - get api
export async function fetchVerifiedDriverList(slotId, distributorId){
  let url = `${Base}${sendDriverVerifyOtpEndPoint}?slot_id=${slotId}&distributor_id=${distributorId}`;
  return await get_data(url)
  .then((res) => {
    if(res.success){
      return res.data;
    }
    else return Promise.reject(res.error)
  })
  .catch((err) => {
    ErrorHandling(err.message)
    return []
  })
}

// ROUTE LISTING - verified driver list - get api
export async function updateSupervisorOnly(data, slotId){
  let url = Base + updateSupervisorOnlyEndPoint + slotId +'/';
  return put_data(url, data)
  .then((res) => {
    if(res.success){
      SuccessHandling("Successfully updated!!")
      return true;
    }    
    else return Promise.reject(res.error)
  })
  .catch((err) => {
    ErrorHandling(err.message)
    return false
  })
}

//  ROUTE LISTING - enable retailer wise cutoff - put api
export async function enableRetailerCutoff(retailerId){
  let url = `${Base}${enableRetailerCutoffEndpoint}${retailerId}/`;
  return put_data(url, {})
  .then((res) =>{
    if(res.success){
      return true;
    }
    else Promise.reject(res.error);
  }) 
  .catch((error) => {
    ErrorHandling(error.message);
    return false;
  })
}

//  ROUTE LISTING - get route wise unscanned order agent list - get api
export async function getUnscannedOrderDetail(slotId, routeId){
  let url = `${Base}${getUnscannedOrderDetailEndPoint}${slotId}/${routeId}/`

  return get_data(url)
  .then((res) => {
    if(res.success){
      return res;
    }
    else return Promise.reject(res.error);
  })
  .catch((error) => {
    ErrorHandling(error.message)
    return { success: false, data: []}
  })
}

// UPDATE ROUTE - driver list - get api
export async function fetchDriverList(slot){
  let url = Base + sendDriverLoginOtpEndPoint + `?slot_id=${slot}`;
  return get_data(url)
  .then((res)=> {
    if(res.success)
      return res.data;
    else
      return Promise.reject(res.error);
  })
  .catch((error)=>{
    ErrorHandling(error.message);
    return []
  })
}

// UPDATE ROUTE - update route details -  put api
export async function updateRouteDetails(data, retailerList){
  let url = Base + updateRouteDetailsEndPoint;
  let validate = validateData(retailerList)
  if(validate.valid){
    data.retailers = validate.retailers;
    data.delete_retailer_ids = validate.delete_retailer_ids;
    console.log('submit data', data);
    await put_data(url, data)
    .then((res)=> {
      if(res.success){
        SuccessHandling(res.message);
        window.history.back();
      }
      else{
        return Promise.reject(res.error);
      }
    })
    .catch((error)=>{
      ErrorHandling(error.message);
    })
  }
}

// UPDATE ROUTE - unassign route rdriver -  put api
export async function unassignRouteDriver(data){
  let url = Base + unassignRouteDriverEndPoint;
  return put_data(url, data)
  .then((res)=> {
    if(res.success){
      SuccessHandling(res.message);
      return true;
    }
    else{
      return Promise.reject(res.error);
    }
  })
  .catch((error)=>{
    ErrorHandling(error.message);
    return false;
  }) 
}

// ADD NEW ROUTE - new route - post api
export async function addNewRoute(data, retailerList, cityGroupId){
  let url = Base + addNewRouteEndPoint;
  let validate = validateData(retailerList)
  if(validate.valid){
    data.retailers = validate.retailers;
    data.city_group_id = cityGroupId;
    data.delete_retailer_ids = validate.delete_retailer_ids;
    console.log('submit data', data);
    await post_data(url, data)
    .then((res)=> {
      if(res.success){
        SuccessHandling(res.message);
        window.history.back();
      }
      else{
        return Promise.reject(res.error);
      }
    })
    .catch((error)=>{
      ErrorHandling(error.message);
    })
  }
}

// ADD NEW ROUTE - seq route listing - get api
export async function fetchRouteSeq() {
  let url = Base + fetchRouteSeqEndPoint + "?is_priority=1";
  return await get_data(url)
    .then((res) => {
      if (res.success) return res.data;
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      return [];
    });
}

// ADD NEW ROUTE - table listing - get api
export async function fetchTableList() {
  let url = Base + addNewTableEndPoint;
  return await get_data(url)
    .then((res) => {
      if (res.success) return res.data;
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      return [];
    });
}

// RETAILER ORDERS - orders listing - get api
export async function fetchRetailerWiseOrders(
  slot,
  dairy,
  retailer,
  screen,
  cutOff,
  isAll = false
) {
  let url =
    Base +
    fetchRetailerWiseOrdersEndPoint +
    `${slot}/?retailer_id=${retailer}&screen=${screen}&is_cutoff=${cutOff}`;

  if (isAll) {
    url += `&is_all=1`
  }
  else {
    url += `&is_dairy=${dairy}`;
  }
  await get_data(url)
    .then((res) => {
      let data = [];
      for (let order of res.data.retailer_orders) {
        order.crate_numbers = order.crate_numbers
          .split("~")
          .filter((element) => element);
        order.dairy_crate = order.dairy_crate
          .split("~")
          .filter((element) => element);
        data.push(order);
      }
      store.dispatch(setRetailerInfo(res.data.retailer_info));
      store.dispatch(setRetailerPickerInfo(res.data.active_picker_data));
      store.dispatch(setRetailerOrders(data));
    })
    .catch((error) => {
      ErrorHandling(error.message);
    });
}

// RETAILER ORDERS - orders listing - get api
export async function fetchOrderData(
  index,
  dairy,
  orderId,
  isAll
) {
  // dispatch/ds/consumer/orders/details/v1/?order_id=6307538
  let url = Base + fetchOrderDataEndPoint + `?order_id=${orderId}`;
  if(isAll) {
    url += `&is_all=1`;
  }
  else {
    url += `&is_dairy=${dairy}`;
  }
  await get_data(url)
    .then((res) => {
      store.dispatch(
        setOrderDetail({
          data: res.data,
          index: index,
        })
      );
    })
    .catch((error) => {
      ErrorHandling(error.message);
    });
}

// RETAILER ORDERS - table list to update table - get api
export async function fetchCategoryWiseRetalerTable(retailerOrder, dairy){
  let url = Base + fetchCategoryWiseRetalerTableEndPoint + `?order_id=${retailerOrder}&is_dairy=${dairy}`;
  return await get_data(url)
  .then((res)=>{
    if(res.success){
      return res.data;
    }
    else {
      return Promise.reject(res.error);
    }
  })
  .catch((error) => {
    ErrorHandling(error.message);
    return [];
  })
}

// RETAILER ORDERS - update retailer level table / picker - put api
export async function updateRetailerCategoryPicker(data,slot){
  let url = Base + updateRetailerCategoryPickerEndPoint + slot + '/';
  return await put_data(url ,data)
  .then((res) => {
    if(res.success){
      SuccessHandling(res.message);
      return true;
    }
    else return Promise.reject(res.error);
  })
  .catch((error) => {
    ErrorHandling(error.message);
    return false;
  })
}

export function testing() {
  let data = {
    order: 1111,
    retailer: 111,
    unmapped_retailer: 0,
    route: 21,
    item: 132412,
    picker: 123,
    line: 12,
    is_picker_confirmed: false,
  };
  store.dispatch(setInfoData(data));
}

/*--------------------  picker shortfall detail ---------------------- */


export async function fetchShortfallData(slot){
  let url = `${Base}${fetchShortfallDataEndpoint}${slot}/`;

  return await get_data(url)
  .then((res) => {
    if(res.success){
      return res.data;
    }
    else Promise.reject(res.error);
  })
  .catch((error) => {
    ErrorHandling(error.message);
    return []
  })

}



/*--------------------  cold scanning agent order listing ---------------------- */


// cold scanning consumer listing
export async function getAgentConsumerDetails(slotId, agentId, pickerId){
  let url = `${Base}${fetchAgentOrderList}?slot_id=${slotId}&agent_id=${agentId}&picker_id=${pickerId}`
  return await get_data(url)
  .then((res)=>{
    if(res.success){
      return res;
    }
    else {
      return Promise.reject(res.error);
    }
  })
  .catch((error) => {
    ErrorHandling(error.message);
    return {};
  })
}

// COLD ORDER DETAILS - COLD SCANNING - get api
export async function getDairyOrderDetails(orderId){
  let url = Base + fetchOrderDataEndPoint + `?order_id=${orderId}&is_dairy=1`;
  return await get_data(url)
  .then((response) => {
    if(response.success){
      return response.data
    }
    else return Promise.reject(response.error)
  })
  .catch((error) => {
    ErrorHandling(error.message);
    return []
  })
}

// MARK SHORTFALL - AGENT ORDER LISTING - COLD SCANNING - put api
export async function markShortfall(data, orderId, pickerId) {
  let url = Base + fetchRetailerOrderDetailsEndPoint + orderId + "/" + pickerId + "/";
  return await put_data(url, data)
  .then((res) => {
    if(res.success){
      SuccessHandling("Successfully Updated!!")
      return true
    }
    else return Promise.reject(res.error);
  })
  .catch((error) => {
    ErrorHandling(error.message)
    return false
  })
}

/*--------------------  REUSABLE BAGS ---------------------- */

// REUSABLE BAGS - GET RESELLER BAG LEDGER LIST - GET API
export async function fetchReusableBagLedger(slotId, city_id){
  let url = `${Base}${fetchReusableBagLedgerEndPoint}?slot_id=${slotId}&city_id=${city_id}`
  return await get_data(url)
  .then((res)=>{
    if(res.success){
      return res;
    }
    else {
      return Promise.reject(res.error);
    }
  })
  .catch((error) => {
    ErrorHandling(error.message);
    return {};
  })
}

// REUSABLE BAGS - PUT RESELLER BAG LEDGER DATA - PUT API
export async function updateResellerReusableBag(data){
  let url = `${Base}${fetchReusableBagLedgerEndPoint}`
  return await put_data(url, data)
  .then((res)=>{
    if(res.success){
      SuccessHandling('Successfuly Updated!!')
      return res;
    }
    else {
      return Promise.reject(res.error);
    }
  })
  .catch((error) => {
    ErrorHandling(error.message);
    return {};
  })
}

/*--------------------  TABLE STATUS ---------------------- */

// TABLE STATUS - get time duration list - get api
export async function fetchTimeDurationList(slot, isHourly){
  let url = Base + fetchTimeDurationListEndPoint + slot + '/?is_hourly=' + isHourly;
  return await get_data(url)
  .then((res)=>{
    if(res.success){
      return res;
    }
    else {
      return Promise.reject(res.error);
    }
  })
  .catch((error) => {
    ErrorHandling(error.message);
    return {};
  })
}

// TABLE STATUS - get table detail listing  - get api
export async function fetchTableStatusDetail(slot, isHourly, timeSlot){
  let url = Base + fetchTableStatusDetailEndPoint + slot + `/?time_slot=${timeSlot}&is_hourly=${isHourly}`;
  return await get_data(url)
  .then((res)=>{
    if(res.success){
      return res;
    }
    else {
      return Promise.reject(res.error);
    }
  })
  .catch((error) => {
    ErrorHandling(error.message);
    return {};
  })
}

// TABLE STATUS - get table shortfall detail - get api
export async function fetchShortfallDetails(slotId, tableId, shortfallType){
  let url = Base + fetchShortfallDetailsEndPoint + slotId + '/' + tableId + `/?shortfall_type=${shortfallType}`;
  return await get_data(url)
  .then((res)=>{
    if(res.success){
      return res;
    }
    else {
      return Promise.reject(res.error);
    }
  })
  .catch((error) => {
    ErrorHandling(error.message);
    return {};
  })
}

/*--------------------  ONDC ORDER LISTING ---------------------- */

// ONDC ORDER LISTING - get order listing  - get api
export async function fetchOndcOrderList(slot, city_group_id){
  let url = `${Base}${fetchOndcOrderListEndpoint}${slot}/?city_group_id=${city_group_id}`;
  return await get_data(url)
  .then((res)=>{
    if(res.success){
      return res;
    }
    else {
      return Promise.reject(res.error);
    }
  })
  .catch((error) => {
    ErrorHandling(error.message);
    return {};
  })
}

// ONDC ORDER DETAILS - get order details  - get api
export async function fetchOndcOrderDetails(ondcOrderId){
  let url = `${Base}${fetchOndcOrderDetailsEndpoint}/?ondc_order_id=${ondcOrderId}`;
  return await get_data(url)
  .then((res)=>{
    if(res.success){
      return res;
    }
    else {
      return Promise.reject(res.error);
    }
  })
  .catch((error) => {
    ErrorHandling(error.message);
    return {};
  })
}

// ONDC ALL ORDER DETAILS - get all order details - get api 
export async function fetchAllOndcOrderDetails(slotId, city_group_id){
  let url = `${Base}${fetchAllOndcOrderDetailsEndpoint}${slotId}/?city_group_id=${city_group_id}`;
  return await get_data(url)
  .then((res)=>{
    if(res.success){
      return res;
    }
    else {
      return Promise.reject(res.error);
    }
  })
  .catch((error) => {
    ErrorHandling(error.message);
    return {data : []};
  })
}