import {configureStore } from '@reduxjs/toolkit';
import HomeReducer from './HomeReducer';
import InfoPanelReducer from './InfoPanelReducer';
import TableOverviewReducer from './TableOverviewReducer';

export default configureStore({
    reducer: {
        tableOverview: TableOverviewReducer,
        infoPanel: InfoPanelReducer,
        home: HomeReducer,
    //   counter: counterReducer,
    },
  });