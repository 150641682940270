import { createSlice, current } from "@reduxjs/toolkit";
import { deepCopyObject } from "../utils/api";
import { ErrorHandling, SuccessHandling } from "../State";

export const TableSlice = createSlice({
  name: "tableOverview",
  initialState: {
    header: `Welcome, ${localStorage.getItem("ename")}`,
    city: 0,
    category: 0,
    data: {},
    slotEdit: false, 
    slotId: '',
    unmappedList: [],
    tableCount: [],
    tableData: [],
    tableDetail: [],
    dispatchedRoute: 0,
    deliverRoute: 0,
    unassignDairyRoutes: 0,
    routeList: {},
    allDispatched: false, 
    retailerInfo: {},
    retailerPickerInfo: [],
    retailerOrders: [],
    retailerInRoute: [],
    categorySupervisorList: [],
    disableFreeze: true,
    verifyDriver: {phone: "", status: false},
    routeWiseOnlySupervisorChange: {routeId: 0, routeName: "", agentList: [], categoryName: "", categoryId: 0, status: false, supList: []}
  },
  reducers: {
    showPopup: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.data = { name: "tablerOverview" };
    },
    setHeader: (state, action) => {
      state.header = action.payload;
      if(action.payload === "Route List")  
        state.slotEdit = true;
      else 
        state.slotEdit = false
    },
    setSlotId: (state, action) => {
      let ufDate = action.payload;
      if(action.payload.length === 10){
        state.slotId = ufDate.substring(2);
      }
      else if(action.payload.length === 6){
        state.slotId = `${ufDate.substring(4)}-${ufDate.substring(2,4)}-${ufDate.substring(0,2)}`;
      }
    },
    setCity: (state, action) => {
      state.city = action.payload;
      localStorage.setItem('city', JSON.stringify(action.payload) )
    },
    setCategory: (state, action) => {
      state.category = action.payload;
      localStorage.setItem('category', JSON.stringify(action.payload) )
    },
    setUnmappedList: (state, action) => {
      state.unmappedList = action.payload;
    },
    setTableCount: (state, action) => {
      let count = [
        {category: "F&V", count:0},
        {category: "Cold", count:0},
        {category: "Bakery", count:0},
      ];
      for(let line of state.tableData){
        let activeTable = 0;
        for(let picker of line.picker_data){
          activeTable += picker.is_active;
        }
        if(line.line_category[0] === 3)
          count[2].count += activeTable;
        else
          count[line.line_category[0]].count += activeTable;
      }
      console.log("---->", count)
      state.tableCount = count;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setTableDetail: (state, action) => {
      state.tableDetail = action.payload;
      // console.log("STATE SET----->", state.tableDetail)
    },
    setRouteList: (state, action) => {

      let localRouteListing = current(state.routeList)
      // console.log(localRouteListing.routes)
      if(localRouteListing.routes){
        let routeWithRetailerInfo = localRouteListing.routes.filter(
            (route) => {
              if(route.hasOwnProperty('route_retailer_info') || route.hasOwnProperty('cat_route_retailer_info')){
                return route.hasOwnProperty('route_retailer_info') || route.hasOwnProperty('cat_route_retailer_info')
              }
          }
        )
        if(routeWithRetailerInfo.length){
          for(let i=0; i< action.payload.routes.length; i++) {
            let route = action.payload.routes[i]
            let routeInfo = routeWithRetailerInfo.filter(
              routeRetailer =>
              routeRetailer.route_id == route.route_id
            )
            if(routeInfo.length){
              // to append route retaile detail
              if(routeInfo[0].route_retailer_info && routeInfo[0].route_retailer_info.length){
                action.payload.routes[i].route_retailer_info = routeInfo[0].route_retailer_info
                action.payload.routes[i].show_details = true;
              }
              if(routeInfo[0].cat_route_retailer_info && routeInfo[0].cat_route_retailer_info.length){
                action.payload.routes[i].cat_route_retailer_info = routeInfo[0].cat_route_retailer_info
                action.payload.routes[i].show_cat_details = true;
              }
            }
            else{
              action.payload.routes[i].show_details = false;
              action.payload.routes[i].show_cat_details = false;
            }
          }
        }
      }
      // console.log(action.payload)

      state.routeList = action.payload;
      state.disableFreeze = true;
      let dispatchedRoute = 0, deliverRoute = 0, unassignDairyRoutes = 0;
      if(action.payload.routes.length){
        for(let route of action.payload.routes) {
          if(route.locked === 0 ){
            state.disableFreeze = false;
          }
          // console.log("LOCKED", route.locked, route.route_name, state.disableFreeze)
          if(route.status > 2 )
            deliverRoute+=1;
          else {
            dispatchedRoute+=1;
            if(route.dairy > 0){
              let dairySup = route.line_picker_data.filter(supervisor => supervisor.category == 1 )
              if(!dairySup.length){
                route.unassignedDairy = true;
                unassignDairyRoutes++
              }
              else{
                route.unassignedDairy = false;
              }
            }
          }
        }
      }
      state.unassignDairyRoutes = unassignDairyRoutes
      state.deliverRoute = deliverRoute;
      state.dispatchedRoute = dispatchedRoute;
    },
    setToggleRouteDetail: (state, action) => {
      if(action.payload.is_cat){
        state.routeList.routes[action.payload.index].show_cat_details =
        action.payload.status;
      }
      else{
        state.routeList.routes[action.payload.index].show_details =
          action.payload.status;
      }
    },
    setRouteWiseRetailer: (state, action) => {
      if(action.payload.is_cat){
        state.routeList.routes[action.payload.index].cat_route_retailer_info =
          action.payload.data;
      }
      else{
        state.routeList.routes[action.payload.index].route_retailer_info =
          action.payload.data;
      }
    },
    setRetailerInfo: (state, action) => {
      state.retailerInfo = action.payload;
    },
    setRetailerPickerInfo: (state, action) => {
      state.retailerPickerInfo = action.payload;
    },
    setRetailerOrders: (state, action) => {
      state.retailerOrders = action.payload;
    },
    setOrderDetail: (state, action) => {
      state.retailerOrders[action.payload.index].order = action.payload.data;
    },
    setEditRetailerData: (state, action) => {
      let retailerList = [];
      for(let ret of action.payload){
        ret.is_deleted=false;
        retailerList.push(ret);
      }
      state.retailerInRoute = retailerList;
    },
    editRetailerData: (state, action) => {
      let payload = action.payload;
      if(payload.type === 'enable')
      {
        state.retailerInRoute[payload.index].is_deleted =  payload.status;
      }
      else{
        state.retailerInRoute[payload.index].order_info.delivery_number =  payload.delivery_number
      }
    },
    addRetailerInNewRoute: (state, action) => {
      let temp = action.payload.routeRetailer;
      let selRetailer = deepCopyObject(action.payload.item)
      let check = true;

      // checking if the selected retailer is already in the route
      for (let retailer of temp) {
        if (retailer.retailer_id === action.payload.item.retailer_id) {
          check = false;
          console.log("retaile ----- >>>>", retailer);
        }
      }

      // updating delivery_number for retailer without retailer id
      if(selRetailer.order_info.delivery_number === ""){
        selRetailer.order_info.delivery_number = state.retailerInRoute.length + 1
      }

      if (check) {
        state.retailerInRoute.push(selRetailer);
      }
      let temp_data = state.unmappedList;
      temp_data.splice(action.payload.index, 1);
      state.unmappedList = temp_data;
    },
    setCategorySupervisorList: (state, action) =>{
      state.categorySupervisorList = action.payload;
    },
    toggleDisable: (state, action) => {
      // console.log("payload", action.payload);
      let payload = action.payload;
      state.tableData[payload.lineIdx].picker_data[
        payload.pickerIdx
      ].is_active =
        !state.tableData[payload.lineIdx].picker_data[payload.pickerIdx]
          .is_active;
    },
    setVerifyDriver: (state, action) => {
      state.verifyDriver = action.payload
    },
    setOnlySupervisorChange: (state, action) => {
      let categoryList = current(state.categorySupervisorList)
      let supversiorList = categoryList.filter((category) => category.id == action.payload.categoryId)

      action.payload.supList = supversiorList[0]? supversiorList[0].lines : []

      state.routeWiseOnlySupervisorChange = action.payload;
    },
    sortByPartnersDelivered: (state,action) => {

      let deliveredRoutes = action.payload.routes.filter((route) => route.status > 2)

      if(deliveredRoutes.length){
        const localRoutes = deepCopyObject(action.payload.routes);

        localRoutes.map((route)=>{
          route.un_delivered_partners = route.agent_details.filter((ele) => !ele.is_delivered).length
        })

        localRoutes.sort((r1,r2) => {
          return r2.un_delivered_partners - r1.un_delivered_partners;
        })
        
        state.routeList.routes = localRoutes;
        SuccessHandling("Routes Sorted Successfully")
      }else{
        ErrorHandling("No Routes Present !!");
      }
    }
  },
});

// table selection data
export const fetchTableData = (data) => (dispatch) => {
  // console.log("Dispatch", dispatch);
  dispatch(setTableData(data));
};

// table dashboard data
// export const fetchTableDetail = (data) => (dispatch) => {
//   dispatch(setTableDetail(data));
// };

export const {
  showPopup,
  setHeader,
  setSlotId,
  setCity,
  setCategory,
  setUnmappedList,
  setTableCount,
  setTableData,
  setTableDetail,
  setRouteList,
  setRouteWiseRetailer,
  setToggleRouteDetail,
  setRetailerInfo,
  setRetailerPickerInfo,
  setRetailerOrders,
  setOrderDetail,
  setEditRetailerData,
  editRetailerData,
  addRetailerInNewRoute,
  toggleDisable,
  setCategorySupervisorList,
  setVerifyDriver,
  setOnlySupervisorChange,
  sortByPartnersDelivered
} = TableSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

// export const incrementAsync = (amount) => (dispatch) => {
//   console.log("Dispatch", dispatch);
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount));
//   }, 1000);
// };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

// export const selectCount = (state) => state.counter.value;

export default TableSlice.reducer;
