import Vehicle from "../../icons/vehicle.png";
import Home from "../../icons/home-icon.png";
import Scanner from "../../icons/barcode-scan.png";
import Experiment from "../../icons/experiment.svg";
import TableOverview from "../../icons/table-drawer.png";
import RouteListing from "../../icons/route-icon.svg";
import Supervisor from "../../icons/supervisor_icon_white.png";
import ColdScanning from "../../icons/cold-white.svg";
import ReturnBag from "../../icons/return-bag.png";
import BarChart from "../../icons/bars-chart.svg";
import CheckListIcon from "../../icons/checkList.svg";
import WhiteBagIcon from "../../icons/white-bag.svg";
import BagDeleteIcon from "../../icons/bag-delete.svg";
import ShortfallIcon from "../../icons/stock-out.svg";

export const SidebarData = [
  {
    title: "HOME PAGE",
    hasDate: false,
    path: "/",
    cName: "nav-item",
    image: Home,
  },
  {
    title: "TABLE DASHBOARD",
    // path: "/dis-front/route",
    hasDate: false,
    path: "/table-dashboard/",
    cName: "nav-item",
    image: TableOverview,
  },
  {
    title: "TABLE STATUS",
    // path: "/dis-front/route",
    hasDate: true,
    path: "/table-status/",
    pathEnd: "/",
    cName: "nav-item",
    image: BarChart,
  },
  {
    title: "SHORTFALL DASHBOARD",
    // path: "/dis-front/route",
    hasDate: true,
    path: "/shortfall-dashboard/",
    pathEnd: "/",
    cName: "nav-item",
    image: ShortfallIcon,
  },
  {
    title: "SUPERVISOR",
    // path: "/dis-front/route",
    hasDate: false,
    path: "/supervisor/",
    cName: "nav-item",
    image: Supervisor,
  },
  {
    title: "COLD SCAN ORDER",
    path: "/cold-scanning/",
    cName: "nav-item",
    image: ColdScanning,
  },
  {
    title: "F&V SCAN ORDER",
    hasDate: false,
    path: "/fnv-scanning/",
    cName: "nav-item",
    image: Scanner,
  }, 
  {
    title: "MULTI F&V SCAN ORDER",
    hasDate: false,
    path: "/multi-fnv-scanning/",
    cName: "nav-item",
    image: Experiment,
    // is_experimental: true,
  }, 
  {
    title: "ROUTE LISTING",
    // path: "/dis-front/route",
    hasDate: true,
    path: `/route/route-detail/`,
    pathEnd: '/0/',
    cName: "nav-item",
    image: RouteListing,
  },
  {
    title: "CATEGORY ROUTE LISITNG",
    hasDate: true,
    path: `/routedetail/`,
    pathEnd: '/',
    cName: "nav-item",
    image: Vehicle,
  },
  // {
  //   title: "SCAN ORDER",
  //   hasDate: false,
  //   path: "/scanning/",
  //   cName: "nav-item",
  //   image: Scanner,
  // },
  // {
  //   title: "COLD SCAN ORDER",
  //   hasDate: false,
  //   path: "/cold-scanning/",
  //   cName: "nav-item",
  //   image: Scanner,
  // },
  // {
  //   title: "REUSABLE BAGS",
  //   hasDate: true,
  //   path: `/return-bag/`,
  //   pathEnd: '/',
  //   cName: "nav-item",
  //   image: ReturnBag,
  // },
  {
    title: "DC BAG INVENTORY",
    hasDate: false,
    path: "/dc-bag-inventory/",
    cName: "nav-item",
    image: CheckListIcon,
  }, 
  {
    title: "PARTNER BAG INVENTORY",
    hasDate: false,
    path: "/partner-bag-inventory/",
    cName: "nav-item",
    image: WhiteBagIcon,
  }, 
  {
    title: "REUSABLE RTD",
    hasDate: false,
    path: `/reusable-return-bag/`,
    cName: "nav-item",
    image: ReturnBag,
  },
  {
    title: "DEBIT REUSABLE BAG",
    hasDate: false,
    path: `/debit-reusable-bag/`,
    cName: "nav-item",
    image: BagDeleteIcon,
  },
  {
    title: "ONDC ORDERS",
    hasDate: true,
    path: `/ondc-orders/`,
    pathEnd: '/',
    cName: "nav-item",
    image: Vehicle,
  },
];
