import { createSlice } from "@reduxjs/toolkit";

export const InfoPanelSlice = createSlice({
  name: "infoPanelReducer",
  initialState: {
    confirmPopup: false,
    tableAddPopup: false,
    supervisorPopup: false,
    routeSupervisorPopup: false,
    addDriverPopup: false,
    moveTablePopup: false,
    routeMovePopup: false,
    tableConfirmed: true,
    addRetailerPopup: false,
    retailerToRoutePopup: false,
    cityColdSupervisorPopup: false,
    dispatchRoute: false,
    unmapped: 6,
    data: {
      order: 0,
      retailer: 0,
      unmapped_retailer: 0,
      route: 0,
      item: 0,
      picker: 0,
      line: 0,
      is_picker_confirmed: false,
    },
  },
  reducers: {
    reset: (state, action) => {
      // return InfoPanelSlice.getInitialState();
    },
    togglePopup: (state, action) => {
      state[action.payload] = !state[action.payload];
    },
    confirmTable: (state, action) => {
      state.tableConfirmed = action.payload;
    },
    setInfoData: (state, action) => {
      state.data = action.payload;
      //   console.log("DATA IN REDUER",action.payload)
    },
  },
});

export const fetchInfoData = (data) => (dispatch) => {
  // console.log("Dispatch", dispatch);
  dispatch(setInfoData(data));
};

export const { togglePopup, reset, confirmTable, setInfoData } =
  InfoPanelSlice.actions;

export default InfoPanelSlice.reducer;
